import providerService from '../../../services/provider/email'
import notisphereServices from '../../../services/notisphere/email'
import EmailModal from './EmailModal'
import store from '../../../store'
export default {
    components: {
        EmailModal
    },
    data() {
        return {
            total: 0,
            items: [],
            searchQuery: {},
            header: [
                {
                    label: '',
                    name: 'name',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right'
                }
            ],

            emailModalKey: 0,
            orgType: store.state.userType,
            service: store.state.userType =='PROVIDER' ? providerService: notisphereServices,
        }
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
                this.fetchData()
            }
        },
        updateEmailTemplate(payload, e = null) {
            if (this.orgType == 'PROVIDER') {
                this.$router.push({ name: 'prov_EmailTemplate_Edit', params: { id: payload.email_type } })
            }
            else
            {
                this.$router.push({ name: 'noti_EmailTemplate_Edit', params: { id: payload.email_type } })
            }
            e.stopPropagation()
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            this.service.findEmails().then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d
                    this.total = resp.data.d.length ? resp.data.d.length : 0
                }
            })
        }
    }
}